.text-content {
	p {
		@apply py-4;
	}
	a {
		@apply underline;
	}
	h1, h2, h3, h4 {
		@apply leading-tight;
		@apply py-3;
	}
	h1 {
		@apply text-4xl;
	}
	h2 {
		@apply text-3xl;
	}
	h3 {
		@apply text-2xl;
	}
	h4 {
		@apply text-xl;
	}
	p img {
		display: inline-block;
	}
	img.fr-dii.fr-fir {
		@apply float-right;
		@apply ml-10;
	}
	img.fr-dii.fr-fil {
		@apply float-left;
		@apply mr-10;
	}
	ul {
		padding-left: 30px;

		li {
			list-style-type: circle;
		}
	}
	img {
		max-width: 100%;
		height: auto !important;
	}
	iframe {
		max-width: 100%;
		margin: 0 auto;
	}
}
.p-without-padding {
	p {
		padding: 2px 0 !important;
	}
}