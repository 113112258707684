@import "web/_variables.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/utilities/align";
// @import "~bootstrap/scss/utilities/background";
// @import "~bootstrap/scss/utilities/clearfix";
// @import "~bootstrap/scss/utilities/display";
// @import "~bootstrap/scss/utilities/embed";
// @import "~bootstrap/scss/utilities/flex";
// @import "~bootstrap/scss/utilities/float";
// @import "~bootstrap/scss/utilities/interactions";
// @import "~bootstrap/scss/utilities/screenreaders";
// @import "~bootstrap/scss/utilities/stretched-link";

// @import "~aos/dist/aos.css";
// @import "web/bootstrap_extras";
// @import "web/hover_animation";
// @import "web/landkit_shape";
// @import "web/avatar";

@import "tailwind";
@import "web/tailwind_extras";

[x-cloak] {
    display: none !important;
}

details>summary {
    list-style: none;
}

details>summary::marker,
details>summary::-webkit-details-marker {
    display: none;
}